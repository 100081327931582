.userSettings {
  padding: 0 1rem;
  user-select: none;
}

.userSettings:hover,
.userSettings:active {
  cursor: pointer;
  transition: 0.3s;
}

.userAvatar {
  margin-inline-start: 0.5rem;
}