* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

body {
  font-family: 'Cairo', sans-serif;
}

.ant-layout-sider-trigger {
  position: static !important;
}

.drivers-list .ant-list-items {
  height: 31.3rem;
  overflow-x: auto;
}

/* Firefox */
* {
  scrollbar-width: thin;
  scrollbar-color: #f55a00 transparent;
}

/* Chrome, Edge and Safari */
*::-webkit-scrollbar {
  width: 7px;
  width: 7px;
}
*::-webkit-scrollbar-track {
  border-radius: 2px;
  background-color: transparent;
}

*::-webkit-scrollbar-track:hover {
  background-color: transparent;
}

*::-webkit-scrollbar-track:active {
  background-color: transparent;
}

*::-webkit-scrollbar-thumb {
  border-radius: 2px;
  background-color: #f55a00;
}

*::-webkit-scrollbar-thumb:hover {
  background-color: #f55a00;
}

*::-webkit-scrollbar-thumb:active {
  background-color: #f55a00;
}
