.mapCard {
  margin-top: 1rem;
}

.item {
  padding-inline-end: 0 !important;
}

.driverslist {
  height: 35rem;
  overflow-x: auto;
}
