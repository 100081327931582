.card {
	margin-bottom: 1rem;
}

.title {
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	align-items: center;
	padding: 1rem;
}
