.headerCard {
  margin-bottom: 1rem;
}

.headerCardTitle {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 1rem;
}

.headerCardTitleTrailing {
  display: flex;
  flex-direction: row;
}

.saveButton {
  margin-inline-start: 0.5rem;
}

.photoContainer {
  margin-inline-end: 1rem;
  margin-bottom: 0.5rem;
  width: 7rem;
  border: 1px solid #d9d9d9;
  border-radius: 8px;
  padding: 0.5rem;
}
