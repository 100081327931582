.container {
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  background-image: url('../../assets/images/auth-pg.jpeg');
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;
}

.layer {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.2);
  @supports (backdrop-filter: blur(3px)) {
    /* Styling for browsers that do support backdrop-filter */
    background-color: rgba(0, 0, 0, 0.2);
    backdrop-filter: blur(3px);

    /* Prefixed property for older browsers */
    -webkit-backdrop-filter: blur(2px);
  }
}

.card {
  width: 400px;
}

.title {
  color: #f55a00;
}
.subtitle {
  color: #767676;
  font-size: medium;
  font-weight: normal;
  padding-inline-start: 1.2rem;
}

.header {
  padding: 1rem 0;
}

.brandLogoContainer {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  position: relative;
}

.brandLogo {
  height: 5rem;
  width: 100%;
  object-fit: contain;
  padding: 0.5rem;
}
