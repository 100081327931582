.chartsCard {
  padding-bottom: 2rem;
  margin-bottom: 1rem;
}

.col {
  display: flex;
  justify-content: center;
  align-items: center;
}
