.page {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  height: 100dvh;
}

.loadingContainer {
}

.loader {
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: bold;
  color: #9ca3af;
}

.inlineFlex {
  display: inline-flex;
}
.easeIn {
  transition-timing-function: ease-in;
}
.animateBounce {
  animation: bounce 1s infinite;
}
.rounded {
  border-radius: 100%;
  overflow: hidden;
  height: 0.5rem;
  width: 0.5rem;
  margin: 2rem 0.5rem 0.5rem 0.5rem;
  background-color: #f55a00;
}
@keyframes bounce {
  0%,
  100% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(-15px);
  }
}

.animationDelay1 {
  animation-delay: 0.1s;
}
.animationDelay2 {
  animation-delay: 0.2s;
}
.animationDelay3 {
  animation-delay: 0.3s;
}
